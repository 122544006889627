import styled from 'styled-components'

export const MainContainer = styled.div`
  display: flex;
  margin: 5rem 0;
  @media (max-width: 960px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    margin: 2rem 0 6rem;
    justify-content: center;
  }
`

export const EmbeddedVideo = styled.iframe.attrs((props: { height: string }) => props.height)`
  width: 100%;
  height: ${({ height }) => height};
`
export const EmbeddedVideoContainer = styled.div.attrs(
  (props: {
    ref:
      | ((instance: HTMLDivElement | null) => void)
      | React.RefObject<HTMLDivElement>
      | null
      | undefined
  }) => props.ref
)`
  display: flex;
  align-items: center;
  width: calc(60% - 5rem);

  @media (max-width: 960px) {
    width: 100%;
  }
`

export const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 40%;
  margin-left: 5rem;

  > h1,
  p {
    text-align: left;
    margin-bottom: 25px;
  }

  > p:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: 960px) {
    width: 100%;
    margin-left: auto;
    align-items: center;
    margin-bottom: 25px;
    > h1,
    p {
      text-align: center;
      width: 50%;
    }
  }
`

export const Heading = styled.h1`
  font-size: 2.5rem;
  font-weight: 600;
  color: #2a107b;
  margin-top: 0;
  margin-bottom: 2rem;
  width: 85%;
`

export const Text = styled.p`
  font-size: 1.5rem;
  font-weight: 100;
  margin: 0;
  width: 85%;
`
