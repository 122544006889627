import * as React from 'react'
import * as S from './DemoAndSupportAtoms'
import { StaticImage } from 'gatsby-plugin-image'
import socials from '../../../constants/socials'

const DemoAndSupport: React.FC = () => (
  <>
    <S.DemoContainer>
      <S.TextBlock id="demo">
        <S.Heading>Would you like to know more?</S.Heading>
        <S.Text>
          Book an online Demo with one of our experts and we will show you all the features your
          team can leverage from.
        </S.Text>
        <S.CallToActionBlock>
          <S.CallToActionButton href={socials.BOOK_A_DEMO} target="_blank">
            Book a Demo
          </S.CallToActionButton>
        </S.CallToActionBlock>
      </S.TextBlock>
      <S.StaticImageWrapper>
        <StaticImage
          src="../../../assets/svgs/request-demo.svg"
          alt="Book a demo for Power Retro"
          layout="constrained"
          placeholder="none"
          quality={100}
        />
      </S.StaticImageWrapper>
    </S.DemoContainer>
    <S.SupportContainer>
      <S.StaticImageWrapper>
        <StaticImage
          src="../../../assets/svgs/request-support.svg"
          alt="Request support for Power Retro"
          layout="constrained"
          placeholder="none"
          quality={100}
        />
      </S.StaticImageWrapper>

      <S.TextBlock id="support">
        <S.Heading>Need some technical help?</S.Heading>
        <S.Text>
          Ask a question or report a problem and follow-up your issues in our Help Center.
        </S.Text>
        <S.CallToActionBlock>
          <S.CallToActionButton href={socials.SUPPORT} target="_blank">
            Submit Request
          </S.CallToActionButton>
        </S.CallToActionBlock>
      </S.TextBlock>
    </S.SupportContainer>
  </>
)

export default DemoAndSupport
