import * as React from 'react'
import * as S from './PowerUpAtoms'
import Slider from 'react-slick'
import SliderButton from '../../SliderButton'
import AtlassianLogoBlue from '../../../assets/svgs/atlassian-logo-blue.svg'
import useWindowWidth from '../../../hooks/useWindowWidth'
import breakpoints from '../../../constants/breakpoints'
import { StaticImage } from 'gatsby-plugin-image'
import socials from '../../../constants/socials'

const PowerUp: React.FC = () => {
  const width = useWindowWidth()

  let atlassianLogoWidth: number | undefined
  let atlassianLogoHeight: number
  let sliderButtonSize: 'small' | 'medium' | 'large'

  if (width >= breakpoints.XL) {
    atlassianLogoHeight = 64
    sliderButtonSize = 'large'
  } else if (width >= breakpoints.SM) {
    atlassianLogoHeight = 50
    sliderButtonSize = 'medium'
  } else if (width >= breakpoints.XS) {
    atlassianLogoWidth = 340
    atlassianLogoHeight = 38
    sliderButtonSize = 'small'
  } else {
    atlassianLogoWidth = 300
    atlassianLogoHeight = 38
    sliderButtonSize = 'small'
  }

  return (
    <S.Container>
      <S.InfoBlock>
        <S.Heading>Power Up</S.Heading>
        <S.SubHeading>your retrospectives</S.SubHeading>
        <S.Text>
          Supercharged with <strong>AI capabilities</strong>, our interactive Atlassian Jira tool
          empowers collaboration and maximises your team's productivity.
        </S.Text>
        <S.BrandWrapper>
          <S.StaticImageWrapper>
            <StaticImage
              src="../../../assets/images/jira-logo-blue.png"
              alt="Jira logo"
              layout="fixed"
              placeholder="none"
              quality={100}
            />
          </S.StaticImageWrapper>
          <S.AtlassianIconWrapper>
            <AtlassianLogoBlue width={atlassianLogoWidth} height={atlassianLogoHeight} />
          </S.AtlassianIconWrapper>
        </S.BrandWrapper>
        <S.StartFreeTrialBlock>
          <S.Text>
            Set it up today from the Atlassian Marketplace and enjoy your 30 days free trial.
          </S.Text>
          <S.StartFreeTrialButton href={socials.MARKETPLACE_1} target="_blank">
            Start free trial
          </S.StartFreeTrialButton>
        </S.StartFreeTrialBlock>
      </S.InfoBlock>
      <S.SliderContainer>
        <Slider
          arrows
          prevArrow={<SliderButton size={sliderButtonSize} direction="left" />}
          nextArrow={<SliderButton size={sliderButtonSize} direction="right" />}
          infinite
          speed={500}
          slidesToShow={1}
          slidesToScroll={1}
          autoplay
          autoplaySpeed={5000}
          cssEase="linear"
          pauseOnHover
          fade
        >
          <div>
            <StaticImage
              src="../../../assets/svgs/setup-wizard.svg"
              alt="Power Retro project setup"
              layout="constrained"
              placeholder="none"
              quality={100}
            />
            <S.SliderText>
              AI‑Powered efficiency at your fingertips to help you improve your agile workflow and
              retrospectives.
            </S.SliderText>
          </div>
          <div>
            <StaticImage
              src="../../../assets/svgs/scrum-board.svg"
              alt="Power Retro interactive boards"
              layout="constrained"
              placeholder="none"
              quality={100}
            />
            <S.SliderText>
              Elevate your teamwork and collaborartion with interactive boards, intuitive UI and
              customizable templates.
            </S.SliderText>
          </div>
          <div>
            <StaticImage
              src="../../../assets/svgs/team-work.svg"
              alt="Power Retro teamwork"
              layout="constrained"
              placeholder="none"
              quality={100}
            />
            <S.SliderText>
              Provide valuable insights in no time by turning action items into Jira tickets and
              enhanced summaries.
            </S.SliderText>
          </div>
        </Slider>
      </S.SliderContainer>
    </S.Container>
  )
}

export default PowerUp
