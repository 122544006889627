import styled from 'styled-components'

export const Container = styled.article`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  padding-bottom: 2rem;

  @media (max-width: 960px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`

export const InfoBlock = styled.div`
  width: 600px;
  margin-top: 2.5rem;

  @media (max-width: 360px) {
    width: 300px;
  }

  @media (max-width: 960px) {
    text-align: center;
    margin-bottom: 2.5rem;
  }

  @media (min-width: 960px) and (max-width: 1400px) {
    width: 450px;
  }
`

export const Heading = styled.h1`
  font-size: 4.25rem;
  font-weight: 800;
  color: #2a107b;
  text-transform: uppercase;
  margin: 0;
`

export const SubHeading = styled.h2`
  font-size: 3.25rem;
  font-weight: 800;
  color: #2a107b;
  margin: 0 0 2rem;
`

export const Text = styled.p`
  font-size: 2rem;
  font-weight: 100;
  color: #2a107b;
  margin: 0 0 2rem;
`

export const BrandWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media (max-width: 1400px) {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 2rem;
    justify-content: center;
    margin-bottom: 2rem;

    svg {
      justify-self: center;
    }
  }
`

export const StaticImageWrapper = styled.div`
  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
    transform: scale(0.8);
  }

  @media (min-width: 600px) and (max-width: 768px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  @media (min-width: 768px) and (max-width: 1400px) {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-left: -2.5rem;
  }
`

export const AtlassianIconWrapper = styled.div`
  @media (min-width: 1400px) {
    position: absolute;
    bottom: -4px;
    right: 0;
  }

  @media (min-width: 1920px) {
    position: absolute;
    bottom: -10px;
    right: 0;
  }
`

export const SliderContainer = styled.div`
  width: 200px;
  position: relative;

  @media (min-width: 360px) {
    width: 220px;
  }

  @media (min-width: 600px) {
    width: 350px;
  }

  @media (min-width: 1920px) {
    width: 500px;
  }

  button[class*='slick-prev'] {
    &:before {
      content: '';
    }

    @media (min-width: 320px) {
      left: -55px;
    }

    @media (min-width: 600px) {
      left: -90px;
    }

    @media (min-width: 1024px) {
      left: -60px;
    }

    @media (min-width: 1280px) {
      left: -70px;
    }
  }

  button[class*='slick-next'] {
    &:before {
      content: '';
    }

    @media (min-width: 320px) {
      right: -55px;
    }

    @media (min-width: 600px) {
      right: -90px;
    }

    @media (min-width: 1024px) {
      right: -60px;
    }

    @media (min-width: 1280px) {
      right: -70px;
    }
  }
`

export const SliderText = styled.p`
  font-size: 1.5rem;
  font-weight: 100;
  color: #2a107b;
  margin: 2rem 0 0;
`

export const StartFreeTrialBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`

export const StartFreeTrialButton = styled.a`
  height: fit-content;
  width: fit-content;
  display: block;
  font-size: 1.5rem;
  font-weight: 600;
  border: none;
  background-color: #2a107b;
  color: #fff;
  padding: 1rem 2rem;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  margin: 0 0 0.3rem;

  &:hover {
    background-color: #37159e;
  }

  &:active {
    margin: 0.3rem 0 0;
  }

  @media (max-width: 960px) {
    padding: 1.5rem 2rem;
    align-self: center;
  }
`
