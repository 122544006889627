import * as React from 'react'
import * as S from './FundingAtoms'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

const Funding: React.FC = () => (
  <S.Container>
    <StaticImage
      src="../../../assets/images/nkfia.png"
      alt="Project financed from the NRDI fund"
      placeholder="none"
      quality={100}
      width={300}
    />
    <S.Text>
      “Project no. 2020-1.1.1-KKV START has been implemented with the support provided by the
      Ministry of Innovation and Technology of Hungary from the National Research, Development and
      Innovation Fund, financed under the{' '}
      <i>
        Olyan Atlassian Marketplace kiegészítő létrehozása, amely segít a csapatoknak retrospektív
        elemek létrehozásában, rendszerezésében és követésében
      </i>{' '}
      funding scheme.”
    </S.Text>

    <S.PressReleaseLink to="/kkv-palyazat-sajtokozlemeny">Press Release</S.PressReleaseLink>
  </S.Container>
)

export default Funding
