import * as React from 'react'
import * as S from './SliderButtonAtoms'
import { CustomArrowProps } from 'react-slick'
import ArrowIcon from '../../assets/svgs/arrow.svg'

type SliderButtonProps = CustomArrowProps & {
  direction: 'left' | 'right'
  size: 'small' | 'medium' | 'large'
}

const SliderButton: React.FC<SliderButtonProps> = ({ direction, size, ...otherProps }) => {
  const sizes = {
    small: 30,
    medium: 40,
    large: 50
  }

  return (
    <S.IconWrapper {...otherProps} direction={direction} size={sizes[size]}>
      <ArrowIcon width={sizes[size]} height={sizes[size]} />
    </S.IconWrapper>
  )
}

export default SliderButton
