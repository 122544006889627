import styled from 'styled-components'

type IconWrapperProps = {
  direction: 'left' | 'right'
  size: number
}

export const IconWrapper = styled.button<IconWrapperProps>`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  border: none;
  background-color: transparent;
  cursor: pointer;
  transform: ${({ direction }) => `rotate${direction == 'left' ? '(-90deg)' : '(90deg)'}`};

  &:hover path {
    fill: #2a107b;
  }

  &:active path {
    fill: #37159e;
  }
`
