import * as React from 'react'
import * as S from './WorkflowAtoms'
import { StaticImage } from 'gatsby-plugin-image'
import socials from '../../../constants/socials'

const Workflow: React.FC = () => (
  <>
    <S.MainContainer>
      <S.FlexRowWrapper>
        <S.ImageWrapper>
          <StaticImage
            src="../../../assets/images/active-projects-screen.png"
            alt="Power Retro Project View"
            layout="constrained"
            placeholder="none"
            quality={100}
            style={{
              borderRadius: 8,
              boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
            }}
          />
        </S.ImageWrapper>
        <S.InfoBlock>
          <S.Heading>Improve your workflow</S.Heading>
          <S.List>
            <S.ListItem>easy to use voting system</S.ListItem>
            <S.ListItem>turning action items directly into Jira tickets</S.ListItem>
            <S.ListItem>comprehensive summaries with export options(s)</S.ListItem>
            <S.ListItem>
              and loads of event options to tailor the retro events to your needs
            </S.ListItem>
          </S.List>
        </S.InfoBlock>
      </S.FlexRowWrapper>
      <S.StartFreeTrialBlock>
        <S.StartFreeTrialButton href={socials.MARKETPLACE_4} target="_blank">
          Start your 30 days free trial
        </S.StartFreeTrialButton>
      </S.StartFreeTrialBlock>
    </S.MainContainer>
  </>
)

export default Workflow
