import styled from 'styled-components'

export const MainContainer = styled.article`
  display: flex;
  flex-direction: column;
  margin: 5rem 0 2rem;

  @media (max-width: 960px) {
    flex-wrap: wrap;
    margin: 2rem 0 2rem;
  }
`

export const FlexRowWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 960px) {
    flex-wrap: wrap;
    margin: 2rem 0 2rem;
  }

  img {
    border-radius: 5px;
  }
`

export const ImageWrapper = styled.div`
  margin: auto 0;
`

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 360px) {
    width: 300px;
  }

  @media (min-width: 960px) {
    width: 450px;
    margin-right: 1.5rem;
  }
`

export const Heading = styled.h1`
  font-size: 2.5rem;
  font-weight: 600;
  color: #2a107b;
  padding-left: 1rem;
  margin-top: 0;
`

export const List = styled.ul`
  width: 340px;

  @media (max-width: 320px) {
    width: 80%;
  }

  @media (max-width: 960px) {
    columns: 2;
    width: 90%;
    margin-bottom: 2.5rem;
  }
`

export const ListItem = styled.li`
  font-size: 1.5rem;
  font-weight: 100;
  padding-bottom: 0.5rem;

  @media (max-width: 960px) {
    width: 90%;
  }
`
