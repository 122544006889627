import styled from 'styled-components'

export const MainContainer = styled.article`
  display: flex;
  flex-direction: column;
  margin: 5rem 0;
`

export const FlexRowWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 960px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  img {
    border-radius: 5px;
  }
`

export const ImageWrapper = styled.div`
  margin: auto 0;
`

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 360px) {
    width: 300px;
  }

  @media (max-width: 960px) {
  }

  @media (min-width: 960px) {
    width: 450px;
    margin-left: 1.5rem;
  }
`
export const Heading = styled.h1`
  font-size: 2.5rem;
  font-weight: 600;
  color: #2a107b;
  padding-left: 1rem;
  margin-top: 0;
`

export const List = styled.ul`
  width: 340px;

  @media (max-width: 320px) {
    width: 80%;
  }

  @media (max-width: 960px) {
    columns: 1;
    width: 90%;
    margin-bottom: 2.5rem;
  }
`

export const ListItem = styled.li`
  font-size: 1.5rem;
  font-weight: 100;
  padding-bottom: 0.5rem;

  @media (max-width: 960px) {
    width: 90%;
    break-inside: avoid-column;
  }
`

export const ComingSoonBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 8rem 0;

  @media (max-width: 600px) {
    flex-wrap: wrap;
    margin: 0;
  }

  @media (max-width: 960px) {
    flex-wrap: wrap;
  }
`

export const ComingSoonText = styled.h1`
  width: 280px;
  font-size: 1.5rem;
  font-weight: 800;
  color: #2a107b;
  margin: 0 1rem;

  @media (max-width: 600px) {
    width: 200px;
    text-align: center;
    margin: 0 1rem 0;
  }

  @media (min-width: 600px) and (max-width: 960px) {
    width: 280px;
    text-align: center;
    margin: 0 1rem 0;
  }

  @media (min-width: 960px) and (max-width: 1920px) {
    width: 280px;
    margin: 0 3rem;
  }

  @media (min-width: 1920px) {
    width: 300px;
    margin: 0 3rem;
  }
`

export const ApplyBetaButton = styled.a`
  height: fit-content;
  display: block;
  font-size: 1.5rem;
  font-weight: 600;
  border: none;
  background-color: #2a107b;
  color: #fff;
  padding: 1rem 2rem;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  margin: 0 0 0.3rem;

  &:hover {
    background-color: #37159e;
  }

  &:active {
    margin: 0.3rem 0 0;
  }

  @media (max-width: 960px) {
    padding: 1.5rem 2rem;
  }
`

export const StartFreeTrialBlock = styled.div`
  display: flex;
  flex-direction: row;

  padding-left: 1rem;
  justify-content: left;
  @media (max-width: 960px) {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  @media (min-width: 960px) {
    margin-top: 30px;
  }
`

export const StartFreeTrialButton = styled.a`
  height: fit-content;
  width: fit-content;
  display: block;
  font-size: 1.5rem;
  font-weight: 600;
  border: none;
  background-color: #2a107b;
  color: #fff;
  padding: 1rem 2rem;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  margin: 2rem 0 0rem;

  &:hover {
    background-color: #37159e;
  }

  &:active {
    margin: 0.3rem 0 0;
  }

  @media (max-width: 960px) {
    padding: 1.5rem 2rem;
    align-self: center;
  }
`
