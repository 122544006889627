import styled from 'styled-components'

export const DemoContainer = styled.article`
  display: flex;
  justify-content: space-between;
  margin: 6rem 0;

  @media (max-width: 960px) {
    flex-wrap: wrap;
    margin: 2rem 0 6rem;
  }

  img {
    border-radius: 5px;
  }
`
export const SupportContainer = styled.article`
  display: flex;
  justify-content: space-between;
  margin: 6rem 0;

  @media (max-width: 960px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    margin: 2rem 0 6rem;
  }

  img {
    border-radius: 5px;
  }
`
export const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ id }) => (id === 'support' ? 'flex-start' : 'flex-end')};

  > h1,
  p {
    text-align: ${({ id }) => (id === 'support' ? 'left' : 'right')};
  }

  @media (max-width: 960px) {
    align-items: center;
    > h1,
    p {
      text-align: center;
    }
  }
`

export const Heading = styled.h1`
  font-size: 2.5rem;
  font-weight: 600;
  color: #2a107b;
`

export const Text = styled.p`
  font-size: 1.5rem;
  font-weight: 100;
  margin: 0;
  width: 60%;

  @media (max-width: 960px) {
    margin-bottom: 25px;
  }
`

export const CallToActionBlock = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  justify-content: left;

  @media (max-width: 960px) {
    margin-top: 0;
    margin-bottom: 30px;
  }
`

export const CallToActionButton = styled.a`
  height: fit-content;
  width: fit-content;
  display: block;
  font-size: 1.5rem;
  font-weight: 600;
  border: none;
  background-color: #2a107b;
  color: #fff;
  padding: 1rem 2rem;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  margin: 0 0 0.3rem;

  &:hover {
    background-color: #37159e;
  }

  &:active {
    margin: 0.3rem 0 0;
  }

  @media (max-width: 960px) {
    padding: 1.5rem 2rem;
    align-self: center;
  }
`
export const StaticImageWrapper = styled.div`
  @media (min-width: 960px) {
    height: 50%;
    width: 50%;
  }
`
