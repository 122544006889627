import * as React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Section from '../components/Section'
import Navigation from '../components/Navigation'
import PowerUpArticle from '../components/Articles/PowerUp'
import FeaturingArticle from '../components/Articles/Featuring'
import WorkflowArticle from '../components/Articles/Workflow'
import FundingArticle from '../components/Articles/Funding'
import Footer from '../components/Footer'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import DemoAndSupportArticle from '../components/Articles/DemoAndSupport'
import Video from '../components/Articles/Video'

const Index: React.FC = () => (
  <Layout>
    <Seo image="https://powerretro.io/images/Power_Retro_FB_image.jpg" title="Retrospecitve Tool for Jira" />
    <Section color="primary" clip={{ bottomRight: true }}>
      <Navigation />
    </Section>
    <Section color="white" clip={{ bottomRight: true }}>
      <PowerUpArticle />
    </Section>
    <Section color="grey" clip={{ topLeft: true, bottomLeft: true }}>
      <Video />
    </Section>
    <Section
      color="white"
      clip={{ bottomRight: true }}
      blob={{ alignment: 'right', verticalAlignment: 'top' }}
    >
      <FeaturingArticle />
    </Section>
    <Section
      color="grey"
      clip={{ topLeft: true, bottomLeft: true }}
      blob={{ alignment: 'left', verticalAlignment: 'top' }}
    >
      <WorkflowArticle />
    </Section>
    <Section color="white" clip={{ bottomRight: true }}>
      <DemoAndSupportArticle />
    </Section>
    <Section color="grey" clip={{ topLeft: true, bottomRight: true }}>
      <FundingArticle />
    </Section>
    <Section color="secondary" clip={{ topLeft: true }} glue>
      <Footer />
    </Section>
  </Layout>
)

export default Index
