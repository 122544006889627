import styled from 'styled-components'
import { Link } from 'gatsby'

export const Container = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5rem 0 3rem;
`

export const Text = styled.p<{ italic?: boolean }>`
  font-size: 1.25rem;
  font-weight: 100;
  text-align: center;
  margin: 2rem 0 1rem;
`

export const PressReleaseLink = styled(Link)`
  color: #000;
  font-size: 0.875rem;
  font-weight: 100;
  text-align: center;
  text-decoration: none;
  margin: 0;
`
