import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import * as S from './VideoAtoms'

const Video: React.FC = () => {
  const [videoWidth, setVideoWidth] = useState(0)

  const componentRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const handleResize = () => {
      setVideoWidth(componentRef.current ? componentRef.current.offsetWidth : 0)
    }

    setVideoWidth(componentRef.current ? componentRef.current.offsetWidth : 0)

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [componentRef])

  return (
    <S.MainContainer>
      <S.EmbeddedVideoContainer ref={componentRef}>
        <S.EmbeddedVideo
          height={(videoWidth / 16) * 9 + 'px'}
          src="https://www.youtube.com/embed/7YZSpdHXeuI"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </S.EmbeddedVideoContainer>
      <S.TextBlock>
        <S.Heading>Work smarter, not harder with the power of AI</S.Heading>
        <S.Text>
          Unlock your team's full potential and experience <strong>reduced time</strong> spent on
          manual tasks, <strong>increased efficiency</strong>, and enhanced collaboration.
        </S.Text>
        <S.Text>
          Discover the <strong>AI‑powered capabilities </strong>of Power Retro by switching on the{' '}
          <strong>AI‑assistant</strong> for your team.
        </S.Text>
      </S.TextBlock>
    </S.MainContainer>
  )
}

export default Video
