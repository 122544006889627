import * as React from 'react'
import * as S from './FeaturingAtoms'
import { StaticImage } from 'gatsby-plugin-image'

const Featuring: React.FC = () => (
  <S.MainContainer>
    <S.FlexRowWrapper>
      <S.InfoBlock>
        <S.Heading>Featuring...</S.Heading>
        <S.List>
          <S.ListItem>seamless integration</S.ListItem>
          <S.ListItem>user friendly setup</S.ListItem>
          <S.ListItem>customisable templates</S.ListItem>
          <S.ListItem>idea backlog for efficiency</S.ListItem>
          <S.ListItem>lobby screen to gather your team</S.ListItem>
          <S.ListItem>AI‑enhanced grouping by chatGPT</S.ListItem>
        </S.List>
      </S.InfoBlock>
      <S.ImageWrapper>
        <StaticImage
          src="../../../assets/images/retro-setup-screen.png"
          alt="Power Retro Customizable Templates"
          layout="constrained"
          placeholder="none"
          quality={100}
          style={{
            borderRadius: 8,
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
          }}
        />
      </S.ImageWrapper>
    </S.FlexRowWrapper>
  </S.MainContainer>
)

export default Featuring
