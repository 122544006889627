import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

type SeoProps = {
  title?: string
  description?: string
  image?: string
}

const Seo: React.FC<SeoProps> = ({ title, description, image }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            image
            baseUrl
          }
        }
      }
    `
  )

  const brand = 'Power Retro'
  const seoTitle = title ?? site.siteMetadata?.title
  const seoTrial = '30 days Free Trial'
  const seoDescription = description ?? site.siteMetadata.description
  const seoImageRelative: string = image ?? site.siteMetadata.image
  const seoImage =
    site.siteMetadata.baseUrl + (seoImageRelative.startsWith('/') ? '' : '/') + seoImageRelative

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en'
      }}
      title={seoTitle}
      titleTemplate={`${brand} | %s | ${seoTrial}`}
      meta={[
        {
          name: `description`,
          content: seoDescription
        },
        {
          property: `og:title`,
          content: `${brand} | ${seoTitle} | ${seoTrial}`
        },
        {
          property: `og:description`,
          content: seoDescription
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          property: `og:image`,
          content: seoImage
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:image`,
          content: seoImage
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``
        },
        {
          name: `twitter:title`,
          content: `${brand} | ${seoTitle} | ${seoTrial}`
        },
        {
          name: `twitter:description`,
          content: seoDescription
        }
      ]}
    />
  )
}

export default Seo
